// Import styles
import './main.scss';

import gsap from "gsap";

function getElementContentWidth(element) {
 var styles = window.getComputedStyle(element);
 var padding = parseFloat(styles.paddingLeft) +
               parseFloat(styles.paddingRight);

 return element.clientWidth - padding;
}

//
// Application
//
const ProjectName = {
  // Options / globals
  

  initOnLoad() {
  },

  onResize() {
    console.log('on resize');
  }
};

document.addEventListener('DOMContentLoaded', () => ProjectName.init());
window.addEventListener('load', () => ProjectName.initOnLoad());

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
